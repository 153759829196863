@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-primary;
  @apply text-blue-100;
  @apply font-rubik;
}

.container {
  width: 50rem;
  max-width: 90%;
  margin: 0 auto;
}
